import { gql } from '@apollo/client';

// https://shopify.dev/api/release-notes/2020-01#maximum-input-array-size-for-graphql-operations
export const MAX_ARRAY_SIZE = 250;

/*
 * Contains the graphql queries and mutations we use:
 *  - CREATE_APP_SUBSCRIPTION
 *  - GET_DELIVERY_PROFILES
 *  - GET_DELIVERY_PROFILE
 *  - GET_SHIPPING_OPTIONS
 *  - CREATE_SELLING_PLAN_GROUP
 *  - DELETE_SELLING_PLAN_GROUP
 *  - GET_LOCATIONS
 *  - GET_PRODUCTS
 *  - GET_COLLECTION
 *  - GET_SELLING_PLAN_GROUP
 *  - GET_SELLING_PLAN_GROUP_PRODUCTS
 *  - GET_SELLING_PLAN_GROUPS
 *  - GET_SUBSCRIPTION_CONTRACT
 *  - GET_SUBSCRIPTION_CONTRACT_LINES
 *  - GET_SUBSCRIPTION_CONTRACT_ORDERS
 *  - GET_SUBSCRIPTION_CONTRACTS
 *  - GET_SUBSCRIPTION_CONTRACT_PRODUCTS
 *  - GET_VARIANT
 *  - UPDATE_SELLING_PLAN_GROUP
 *  - UPDATE_PRODUCTS_FOR_SELLING_PLAN_GROUP
 *  - UPDATE_CUSTOMER_PAYMENT_METHOD
 *  - SUBSCRIPTION_CONTRACT_UPDATE
 *  - SUBSCRIPTION_DRAFT_UPDATE
 *  - SUBSCRIPTION_DRAFT_COMMIT
 *  - SUBSCRIPTION_DRAFT_LINE_ADD
 *  - SUBSCRIPTION_DRAFT_LINE_UPDATE
 *  - SUBSCRIPTION_ADD_DISCOUNT
 *  - SUBSCRIPTION_UPDATE_DISCOUNT
 *  - SUBSCRIPTION_REMOVE_DISCOUNT
 *  - CREATE_BASIC_DISCOUNT
 */

export const GET_SHOP_INFO = {
  name: 'getShopInfo',
  gql: gql`
    # Get the ID of the delivery region
    query getShopInfo {
      shop {
        features {
          eligibleForSubscriptions
        }
      }
    }
  `,
};

export const CREATE_APP_SUBSCRIPTION = {
  name: 'appSubscriptionCreate',
  gql: gql`
    mutation appSubscriptionCreate(
      $name: String!
      $returnUrl: URL!
      $trialDays: Int
      $test: Boolean
      $lineItems: [AppSubscriptionLineItemInput!]!
    ) {
      appSubscriptionCreate(
        name: $name
        returnUrl: $returnUrl
        trialDays: $trialDays
        test: $test
        lineItems: $lineItems
      ) {
        userErrors {
          field
          message
        }
        confirmationUrl
        appSubscription {
          id
          lineItems {
            id
            plan {
              pricingDetails {
                __typename
              }
            }
          }
        }
      }
    }
  `,
};

export const GET_DELIVERY_PROFILES = {
  name: 'deliveryProfiles',
  gql: gql`
    # Get the ID of the delivery region
    query deliveryProfiles {
      deliveryProfiles(first: 8) {
        edges {
          node {
            name
            id
            profileLocationGroups {
              locationGroupZones(first: 10) {
                pageInfo {
                  hasNextPage
                }
                edges {
                  cursor
                  node {
                    zone {
                      id
                      countries {
                        name
                        provinces {
                          code
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
};

export const GET_DELIVERY_PROFILE = {
  name: 'getDeliveryProfile',
  gql: gql`
    query getDeliveryProfile($id: ID!) {
      deliveryProfile(id: $id) {
        id
        name
        sellingPlanGroups(first: 10) {
          edges {
            node {
              id
              name
            }
          }
        }
        profileLocationGroups {
          locationGroup {
            id
          }
          locationGroupZones(first: 2) {
            edges {
              node {
                zone {
                  id
                  name
                  countries {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
};

export const UPDATE_DELIVERY_PROFILE_PLANS = {
  name: '',
  gql: gql`
    mutation deliveryProfileUpdate($id: ID!, $profile: DeliveryProfileInput!) {
      deliveryProfileUpdate(id: $id, profile: $profile) {
        profile {
          id
        }
        userErrors {
          field
          message
        }
      }
    }
  `,
};

export const GET_SHIPPING_OPTIONS = {
  name: 'subscriptionDraftShippingOptions',
  gql: gql`
    query subscriptionDraftShippingOptions(
      $draftId: ID!
      $deliveryAddress: MailingAddressInput
    ) {
      subscriptionDraft(id: $draftId) {
        id
        deliveryOptions(deliveryAddress: $deliveryAddress) {
          __typename
          ... on SubscriptionDeliveryOptionResultSuccess {
            deliveryOptions {
              ... on SubscriptionShippingOption {
                title
                price {
                  amount
                  currencyCode
                }
                code
                presentmentTitle
                description
                carrierService {
                  id
                  formattedName
                }
              }
            }
          }
          ... on SubscriptionDeliveryOptionResultFailure {
            message
          }
        }
      }
    }
  `,
};

export const CREATE_SELLING_PLAN_GROUP = {
  name: 'sellingPlanGroupCreate',
  gql: gql`
    mutation sellingPlanGroupCreate(
      $input: SellingPlanGroupInput!
      $resources: SellingPlanGroupResourceInput
    ) {
      sellingPlanGroupCreate(input: $input, resources: $resources) {
        sellingPlanGroup {
          id
          name
          merchantCode
          options
          products(first: 40) {
            edges {
              node {
                id
                title
                totalInventory
                featuredImage {
                  altText
                  height
                  id
                  transformedSrc
                  width
                }
              }
            }
          }
          sellingPlans(first: 12) {
            edges {
              node {
                id
                name
                position
                options
                billingPolicy {
                  __typename
                  ... on SellingPlanRecurringBillingPolicy {
                    anchors {
                      day
                      month
                      type
                    }
                    interval
                    intervalCount
                    maxCycles
                    minCycles
                  }
                }
                deliveryPolicy {
                  __typename
                  ... on SellingPlanRecurringDeliveryPolicy {
                    anchors {
                      day
                      month
                      type
                    }
                    cutoff
                    intent
                    interval
                    intervalCount
                    preAnchorBehavior
                  }
                }
                pricingPolicies {
                  __typename
                  ... on SellingPlanRecurringPricingPolicy {
                    adjustmentType
                    adjustmentValue {
                      __typename
                      ... on SellingPlanPricingPolicyPercentageValue {
                        percentage
                      }
                      ... on MoneyV2 {
                        amount
                        currencyCode
                      }
                    }
                    afterCycle
                    createdAt
                  }
                  ... on SellingPlanPricingPolicyBase {
                    adjustmentType
                    adjustmentValue {
                      __typename
                      ... on SellingPlanPricingPolicyPercentageValue {
                        percentage
                      }
                      ... on MoneyV2 {
                        amount
                        currencyCode
                      }
                    }
                  }
                  ... on SellingPlanFixedPricingPolicy {
                    adjustmentType
                    adjustmentValue {
                      __typename
                      ... on SellingPlanPricingPolicyPercentageValue {
                        percentage
                      }
                      ... on MoneyV2 {
                        amount
                        currencyCode
                      }
                    }
                  }
                }
              }
            }
          }
        }
        userErrors {
          code
          field
          message
        }
      }
    }
  `,
};

export const DELETE_SELLING_PLAN_GROUP = {
  name: 'sellingPlanGroupDelete',
  gql: gql`
    mutation sellingPlanGroupDelete($id: ID!) {
      sellingPlanGroupDelete(id: $id) {
        deletedSellingPlanGroupId
        userErrors {
          code
          field
          message
        }
      }
    }
  `,
};

export const GET_LOCATIONS = {
  name: 'getLocations',
  gql: gql`
    query getLocations {
      locations(first: 20) {
        edges {
          node {
            id
          }
        }
      }
    }
  `,
};

export const GET_SELLING_PLAN_GROUP = {
  name: 'getSellingPlanGroup',
  gql: gql`
    query getSellingPlanGroup($id: ID!) {
      sellingPlanGroup(id: $id) {
        id
        merchantCode
        name
        description
        options
        products(first: 40) {
          edges {
            node {
              id
              title
              totalInventory
              featuredImage {
                altText
                height
                id
                transformedSrc
                width
              }
            }
          }
        }
        sellingPlans(first: 12) {
          edges {
            node {
              id
              name
              options
              position
              billingPolicy {
                __typename
                ... on SellingPlanRecurringBillingPolicy {
                  anchors {
                    day
                    month
                    type
                  }
                  interval
                  intervalCount
                  maxCycles
                  minCycles
                }
              }
              deliveryPolicy {
                __typename
                ... on SellingPlanRecurringDeliveryPolicy {
                  anchors {
                    day
                    month
                    type
                  }
                  cutoff
                  intent
                  interval
                  intervalCount
                  preAnchorBehavior
                }
              }
              pricingPolicies {
                __typename
                ... on SellingPlanRecurringPricingPolicy {
                  adjustmentType
                  adjustmentValue {
                    __typename
                    ... on SellingPlanPricingPolicyPercentageValue {
                      percentage
                    }
                    ... on MoneyV2 {
                      amount
                      currencyCode
                    }
                  }
                  afterCycle
                  createdAt
                }
                ... on SellingPlanPricingPolicyBase {
                  adjustmentType
                  adjustmentValue {
                    __typename
                    ... on SellingPlanPricingPolicyPercentageValue {
                      percentage
                    }
                    ... on MoneyV2 {
                      amount
                      currencyCode
                    }
                  }
                }
                ... on SellingPlanFixedPricingPolicy {
                  adjustmentType
                  adjustmentValue {
                    __typename
                    ... on SellingPlanPricingPolicyPercentageValue {
                      percentage
                    }
                    ... on MoneyV2 {
                      amount
                      currencyCode
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
};

export const GET_SELLING_PLAN_GROUP_PRODUCTS = {
  name: 'getSellingPlanGroupProducts',
  gql: gql`
    query getSellingPlanGroupProducts(
      $id: ID!
      $afterProductVariant: String
      $afterProduct: String
      $productCount: Int
      $productVariantCount: Int
    ) {
      sellingPlanGroup(id: $id) {
        id
        productVariants(
          first: $productVariantCount
          after: $afterProductVariant
        ) {
          edges {
            cursor
            node {
              id
              title
              inventoryQuantity
              sellableOnlineQuantity
              image {
                id
                transformedSrc
              }
              product {
                status
                id
                title
                totalInventory
                featuredImage {
                  transformedSrc
                }
              }
            }
          }
          pageInfo {
            hasNextPage
          }
        }
        products(first: $productCount, after: $afterProduct) {
          edges {
            cursor
            node {
              id
              status
              title
              totalInventory
              featuredImage {
                altText
                id
                transformedSrc
              }
            }
          }
          pageInfo {
            hasNextPage
          }
        }
      }
    }
  `,
};

export const GET_SHOP_PAYMENTS_INFO = {
  name: 'getShopPaymentsInfo',
  gql: gql`
    query getShopPaymentsInfo {
      shop {
        currencyCode
        features {
          eligibleForSubscriptions
        }
      }
    }
  `,
};

export const GET_SELLING_PLAN_FOR_PREVIEW = {
  name: 'getSellingPlansForPreview',
  gql: gql`
    query getSellingPlanGroups($first: Int) {
      sellingPlanGroups(first: $first) {
        edges {
          node {
            id
            products(first: 1) {
              edges {
                node {
                  handle
                }
              }
            }
            productVariants(first: 1) {
              edges {
                node {
                  product {
                    handle
                  }
                }
              }
            }
          }
        }
      }
      shop {
        primaryDomain {
          url
        }
      }
    }
  `,
};

export const GET_SELLING_PLAN_GROUPS = {
  name: 'getSellingPlanGroups',
  gql: gql`
    query getSellingPlanGroups(
      $first: Int
      $last: Int
      $afterCursor: String
      $beforeCursor: String
      $query: String
      $sortKey: SellingPlanGroupSortKeys
      $reverse: Boolean
    ) {
      sellingPlanGroups(
        first: $first
        last: $last
        after: $afterCursor
        before: $beforeCursor
        query: $query
        sortKey: $sortKey
        reverse: $reverse
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            appId
            description
            id
            merchantCode
            name
            options
            productCount
            productVariantCount
            sellingPlans(first: 10) {
              edges {
                node {
                  id
                  name
                }
              }
              pageInfo {
                hasNextPage
              }
            }
          }
        }
      }
    }
  `,
};

/**
 * Optimized query used to know if there are existing selling
 * plan groups or not. It only ask for a single item and request
 * the minimum amount of data.
 */
export const GET_MINIMUM_SELLING_PLAN_GROUPS = {
  name: 'getSellingPlanGroups',
  gql: gql`
    query getSellingPlanGroups {
      sellingPlanGroups(first: 1) {
        edges {
          node {
            id
          }
        }
      }
    }
  `,
};

export const GET_SUBSCRIPTION_CONTRACT_NO_LINES = {
  name: 'getSubscriptionContract',
  gql: gql`
    query getSubscriptionContract($id: ID!) {
      subscriptionContract(id: $id) {
        id
        nextBillingDate
        appAdminUrl
        status
        lineCount
        currencyCode
        note
        discounts(first: 3) {
          edges {
            node {
              id
              type
              title
              recurringCycleLimit
              targetType
              value {
                ... on SubscriptionDiscountFixedAmountValue {
                  amount {
                    currencyCode
                    amount
                  }
                  appliesOnEachItem
                }
                ... on SubscriptionDiscountPercentageValue {
                  percentage
                }
              }
              usageCount
            }
          }
        }
        deliveryPrice {
          amount
          currencyCode
        }
        customerPaymentMethod {
          id
          instrument {
            __typename
            ... on CustomerCreditCard {
              brand
              expiryMonth
              expiryYear
              lastDigits
              name
              billingAddress {
                address1
                city
                country
                province
                provinceCode
                zip
              }
            }
            ... on CustomerShopPayAgreement {
              expiryMonth
              expiryYear
              lastDigits
              name
            }
            ... on CustomerPaypalBillingAgreement {
              paypalAccountEmail
            }
          }
        }
        customer {
          id
          defaultAddress {
            address1
          }
          displayName
          firstName
          lastName
          phone
          email
          image {
            transformedSrc
          }
        }
        originOrder {
          id
          createdAt
          name
          displayFinancialStatus
          displayFulfillmentStatus
          cancelledAt
        }
        billingPolicy {
          interval
          intervalCount
          maxCycles
        }
        deliveryPolicy {
          interval
          intervalCount
        }
        orders(first: 3, reverse: true) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              createdAt
              name
              shippingAddress {
                formatted
                phone
              }
              displayFulfillmentStatus
              displayFinancialStatus
              subtotalPriceSet {
                shopMoney {
                  amount
                  currencyCode
                }
              }
            }
          }
        }
        deliveryMethod {
          __typename
          ... on SubscriptionDeliveryMethodShipping {
            shippingOption {
              code
              description
              presentmentTitle
            }
            address {
              company
              firstName
              lastName
              address1
              address2
              city
              country
              countryCode
              name
              province
              provinceCode
              zip
              phone
            }
          }
        }
      }
    }
  `,
};

export const GET_SUBSCRIPTION_CONTRACT_ONLY_LINES = {
  name: 'getSubscriptionContract',
  gql: gql`
    query getSubscriptionContract($id: ID!, $after: String) {
      subscriptionContract(id: $id) {
        id
        lines(first: 50, after: $after) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            endCursor
          }
          edges {
            cursor
            node {
              id
              productId
              variantId
              variantTitle
              quantity
              title
              sellingPlanId
              currentPrice {
                amount
                currencyCode
              }
              lineDiscountedPrice {
                amount
                currencyCode
              }
              variantImage {
                altText
                transformedSrc
              }
              pricingPolicy {
                basePrice {
                  amount
                  currencyCode
                }
                cycleDiscounts {
                  adjustmentValue {
                    ... on SellingPlanPricingPolicyPercentageValue {
                      percentage
                    }
                    ... on MoneyV2 {
                      amount
                      currencyCode
                    }
                  }
                  afterCycle
                  computedPrice {
                    amount
                    currencyCode
                  }
                  adjustmentType
                }
              }
              discountAllocations {
                amount {
                  amount
                  currencyCode
                }
                discount {
                  __typename
                  ... on SubscriptionAppliedCodeDiscount {
                    id
                    redeemCode
                    rejectionReason
                  }
                  ... on SubscriptionManualDiscount {
                    id
                    recurringCycleLimit
                    rejectionReason
                    targetType
                    title
                    type
                    usageCount
                    value {
                      __typename
                      ... on SubscriptionDiscountFixedAmountValue {
                        amount {
                          amount
                          currencyCode
                        }
                        appliesOnEachItem
                      }
                      ... on SubscriptionDiscountPercentageValue {
                        percentage
                      }
                    }
                    entitledLines {
                      all
                    }
                  }
                }
              }
              customAttributes {
                key
                value
              }
            }
          }
        }
      }
    }
  `,
};

export const GET_SUBSCRIPTION_CONTRACT_LINES = {
  name: 'getSubscriptionContractLines',
  gql: gql`
    query getSubscriptionContractLines($id: ID!) {
      subscriptionContract(id: $id) {
        id
        deliveryPrice {
          amount
          currencyCode
        }
        lines(first: 50) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              productId
              variantId
              variantTitle
              quantity
              title
              currentPrice {
                amount
                currencyCode
              }
              lineDiscountedPrice {
                amount
                currencyCode
              }
              variantImage {
                altText
                transformedSrc
              }
              pricingPolicy {
                basePrice {
                  amount
                  currencyCode
                }
                cycleDiscounts {
                  adjustmentValue {
                    ... on SellingPlanPricingPolicyPercentageValue {
                      percentage
                    }
                    ... on MoneyV2 {
                      amount
                      currencyCode
                    }
                  }
                  afterCycle
                  computedPrice {
                    amount
                    currencyCode
                  }
                  adjustmentType
                }
              }
              discountAllocations {
                amount {
                  amount
                  currencyCode
                }
                discount {
                  __typename
                  ... on SubscriptionAppliedCodeDiscount {
                    id
                    redeemCode
                    rejectionReason
                  }
                  ... on SubscriptionManualDiscount {
                    id
                    recurringCycleLimit
                    rejectionReason
                    targetType
                    title
                    type
                    usageCount
                    value {
                      __typename
                      ... on SubscriptionDiscountFixedAmountValue {
                        amount {
                          amount
                          currencyCode
                        }
                        appliesOnEachItem
                      }
                      ... on SubscriptionDiscountPercentageValue {
                        percentage
                      }
                    }
                    entitledLines {
                      all
                    }
                  }
                }
              }
              customAttributes {
                key
                value
              }
            }
          }
        }
      }
    }
  `,
};

export const GET_SUBSCRIPTION_CONTRACT_BILLING_ATTEMPTS = {
  name: 'getSubscriptionContractBillingAttemps',
  gql: gql`
    query getSubscriptionContractBillingAttemps(
      $id: ID!
      $first: Int
      $last: Int
      $afterCursor: String
      $beforeCursor: String
    ) {
      subscriptionContract(id: $id) {
        id
        billingAttempts(
          first: $first
          last: $last
          before: $beforeCursor
          after: $afterCursor
          reverse: true
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              createdAt
              completedAt
              errorCode
              errorMessage
              ready
              idempotencyKey
              nextActionUrl
              order {
                id
                name
                displayFinancialStatus
                displayFulfillmentStatus
                cancelledAt
              }
            }
          }
        }
      }
    }
  `,
};

export const GET_SUBSCRIPTION_CONTRACTS = {
  name: '',
  gql: gql`
    query getSubscriptionContracts(
      $first: Int
      $last: Int
      $afterCursor: String
      $beforeCursor: String
      $reverse: Boolean
    ) {
      subscriptionContracts(
        first: $first
        last: $last
        after: $afterCursor
        before: $beforeCursor
        reverse: $reverse
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            appAdminUrl
            status
            createdAt
            customer {
              id
              defaultAddress {
                address1
                city
                province
                provinceCode
              }
              canDelete
              createdAt
              displayName
              firstName
              lastName
              email
              image {
                transformedSrc
              }
            }
            nextBillingDate
            originOrder {
              createdAt
            }
            lines(first: 10) {
              edges {
                node {
                  id
                  lineDiscountedPrice {
                    amount
                    currencyCode
                  }
                }
              }
            }
            deliveryPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
  `,
};

export const GET_CUSTOMER = {
  name: 'getCustomer',
  gql: gql`
    query getCustomer($id: ID!) {
      customer(id: $id) {
        id
        displayName
        firstName
        lastName
        email
        phone
        image {
          url
          transformedSrc
        }
      }
    }
  `,
};

export const GET_CUSTOMER_WITH_DATA_POINTS = {
  name: 'getCustomerWithDataPoints',
  gql: gql`
    query getCustomer($id: ID!) {
      customer(id: $id) {
        id
        displayName
        firstName
        lastName
        email
        numberOfOrders

        amountSpent {
          amount
          currencyCode
        }
        subscriptionContracts(first: 20) {
          nodes {
            id
            createdAt
          }
        }
        phone
        image {
          url
          transformedSrc
        }
      }
    }
  `,
};

export const UPDATE_CUSTOMER_PAYMENT_METHOD = {
  name: 'updateCustomerPaymentMethod',
  gql: gql`
    mutation updateCustomerPaymentMethod(
      $customerPaymentMethodId: ID!
      $emailInput: EmailInput
    ) {
      customerPaymentMethodSendUpdateEmail(
        customerPaymentMethodId: $customerPaymentMethodId
        email: $emailInput
      ) {
        customer {
          id
        }
        userErrors {
          field
          message
        }
      }
    }
  `,
};

export const UPDATE_SELLING_PLAN_GROUP = {
  name: 'updateSellingPlanGroup',
  gql: gql`
    mutation updateSellingPlanGroup($input: SellingPlanGroupInput!, $id: ID!) {
      sellingPlanGroupUpdate(id: $id, input: $input) {
        sellingPlanGroup {
          id
          name
          description
          merchantCode
          options
          products(first: 40) {
            edges {
              node {
                id
                title
                totalInventory
                featuredImage {
                  altText
                  height
                  id
                  transformedSrc
                  width
                }
              }
            }
          }
          sellingPlans(first: 12) {
            edges {
              node {
                id
                name
                description
                options
                position
                billingPolicy {
                  __typename
                  ... on SellingPlanRecurringBillingPolicy {
                    anchors {
                      day
                      month
                      type
                    }
                    interval
                    intervalCount
                    maxCycles
                    minCycles
                  }
                }
                deliveryPolicy {
                  __typename
                  ... on SellingPlanRecurringDeliveryPolicy {
                    anchors {
                      day
                      month
                      type
                    }
                    cutoff
                    intent
                    interval
                    intervalCount
                    preAnchorBehavior
                  }
                }
                pricingPolicies {
                  __typename
                  ... on SellingPlanRecurringPricingPolicy {
                    adjustmentType
                    adjustmentValue {
                      __typename
                      ... on SellingPlanPricingPolicyPercentageValue {
                        percentage
                      }
                      ... on MoneyV2 {
                        amount
                        currencyCode
                      }
                    }
                    afterCycle
                    createdAt
                  }
                  ... on SellingPlanPricingPolicyBase {
                    adjustmentType
                    adjustmentValue {
                      __typename
                      ... on SellingPlanPricingPolicyPercentageValue {
                        percentage
                      }
                      ... on MoneyV2 {
                        amount
                        currencyCode
                      }
                    }
                  }
                  ... on SellingPlanFixedPricingPolicy {
                    adjustmentType
                    adjustmentValue {
                      __typename
                      ... on SellingPlanPricingPolicyPercentageValue {
                        percentage
                      }
                      ... on MoneyV2 {
                        amount
                        currencyCode
                      }
                    }
                  }
                }
              }
            }
          }
        }
        userErrors {
          field
          message
        }
      }
    }
  `,
};

export const UPDATE_PRODUCTS_FOR_SELLING_PLAN_GROUP = {
  name: 'updateProductsToSellingPlanGroup',
  gql: gql`
    mutation updateProductsToSellingPlanGroup(
      $id: ID!
      $productIdsToAdd: [ID!]!
      $productIdsToRemove: [ID!]!
      $productVariantIdsToAdd: [ID!]!
      $productVariantIdsToRemove: [ID!]!
    ) {
      sellingPlanGroupRemoveProducts(id: $id, productIds: $productIdsToRemove) {
        removedProductIds
        userErrors {
          field
          message
        }
      }
      sellingPlanGroupAddProducts(id: $id, productIds: $productIdsToAdd) {
        sellingPlanGroup {
          id
          name
        }
        userErrors {
          field
          message
        }
      }
      sellingPlanGroupRemoveProductVariants(
        id: $id
        productVariantIds: $productVariantIdsToRemove
      ) {
        removedProductVariantIds
        userErrors {
          field
          message
        }
      }
      sellingPlanGroupAddProductVariants(
        id: $id
        productVariantIds: $productVariantIdsToAdd
      ) {
        sellingPlanGroup {
          id
          name
        }
        userErrors {
          field
          message
        }
      }
    }
  `,
};

// CANCEL SUBSCRIPTION MUTATIONS:
export const SUBSCRIPTION_CONTRACT_UPDATE = {
  name: 'subscriptionContractUpdate',
  gql: gql`
    mutation subscriptionContractUpdate($subscriptionContractId: ID!) {
      subscriptionContractUpdate(contractId: $subscriptionContractId) {
        draft {
          id
        }
        userErrors {
          code
          field
          message
        }
      }
    }
  `,
};

export const SUBSCRIPTION_DRAFT_UPDATE = {
  name: 'subscriptionContractDraftUpdate',
  gql: gql`
    mutation subscriptionDraftUpdate(
      $draftId: ID!
      $input: SubscriptionDraftInput!
    ) {
      subscriptionDraftUpdate(draftId: $draftId, input: $input) {
        draft {
          id
        }
        userErrors {
          code
          field
          message
        }
      }
    }
  `,
};

export const SUBSCRIPTION_DRAFT_COMMIT = {
  name: 'subscriptionContractDraftCommit',
  gql: gql`
    mutation subscriptionDraftCommit($draftId: ID!) {
      subscriptionDraftCommit(draftId: $draftId) {
        contract {
          id
        }
        userErrors {
          code
          field
          message
        }
      }
    }
  `,
};

export const SUBSCRIPTION_DRAFT_LINE_ADD = {
  name: 'subscriptionContractDraftAddLine',
  gql: gql`
    mutation subscriptionDraftLineAdd(
      $draftId: ID!
      $input: SubscriptionLineInput!
    ) {
      subscriptionDraftLineAdd(draftId: $draftId, input: $input) {
        draft {
          id
        }
        lineAdded {
          id
          variantId
          title
          productId
          variantTitle
        }
        userErrors {
          code
          field
          message
        }
      }
    }
  `,
};

export const SUBSCRIPTION_DRAFT_LINE_UPDATE = {
  name: 'subscriptionContractDraftLineUpdate',
  gql: gql`
    mutation subscriptionDraftLineUpdate(
      $draftId: ID!
      $lineId: ID!
      $input: SubscriptionLineUpdateInput!
    ) {
      subscriptionDraftLineUpdate(
        draftId: $draftId
        lineId: $lineId
        input: $input
      ) {
        draft {
          id
        }
        userErrors {
          field
          message
          code
        }
      }
    }
  `,
};

export const ADD_DISCOUNT_CODE_TO_SC = {
  name: 'addDiscountCodeToSubscriptionContract',
  gql: gql`
    mutation subscriptionDraftDiscountCodeApply(
      $draftId: ID!
      $redeemCode: String!
    ) {
      subscriptionDraftDiscountCodeApply(
        draftId: $draftId
        redeemCode: $redeemCode
      ) {
        appliedDiscount {
          redeemCode
        }

        userErrors {
          field
          message
        }
      }
    }
  `,
};

export const SUBSCRIPTION_DRAFT_LINE_DELETE = {
  name: 'subscriptionContractDraftLineRemove',
  gql: gql`
    mutation subscriptionDraftLineRemove($draftId: ID!, $lineId: ID!) {
      subscriptionDraftLineRemove(draftId: $draftId, lineId: $lineId) {
        lineRemoved {
          id
        }
        draft {
          id
        }
        discountsUpdated {
          id
          entitledLines {
            all
            lines(first: 2) {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
        userErrors {
          field
          message
          code
        }
      }
    }
  `,
};

export const SUBSCRIPTION_ADD_DISCOUNT = {
  name: 'subscriptionContractDraftDiscountAdd',
  gql: gql`
    mutation subscriptionDraftDiscountAdd(
      $draftId: ID!
      $input: SubscriptionManualDiscountInput!
    ) {
      subscriptionDraftDiscountAdd(draftId: $draftId, input: $input) {
        discountAdded {
          id
        }
        draft {
          id
        }
        userErrors {
          field
          message
          code
        }
      }
    }
  `,
};

export const SUBSCRIPTION_UPDATE_DISCOUNT = {
  name: 'subscriptionContractDraftDiscountUpdate',
  gql: gql`
    mutation subscriptionDraftDiscountUpdate(
      $draftId: ID!
      $discountId: ID!
      $input: SubscriptionManualDiscountInput!
    ) {
      subscriptionDraftDiscountUpdate(
        draftId: $draftId
        discountId: $discountId
        input: $input
      ) {
        discountUpdated {
          id
        }
        draft {
          id
        }
        userErrors {
          field
          message
          code
        }
      }
    }
  `,
};

export const SUBSCRIPTION_REMOVE_DISCOUNT = {
  name: 'subscriptionContractDraftDiscountRemove',
  gql: gql`
    mutation subscriptionDraftDiscountRemove($draftId: ID!, $discountId: ID!) {
      subscriptionDraftDiscountRemove(
        draftId: $draftId
        discountId: $discountId
      ) {
        draft {
          id
        }
        userErrors {
          field
          message
          code
        }
      }
    }
  `,
};

/**
 * This query has been designed to avoid having to iterate over the variants of a product.
 *
 * Accordingly to the documentation[1] a product can have up to 100 variants. To avoid
 * exceeding Shopify's query complexity we are only retrieving 9 products in each call,
 * that results in a query complexity of 947 and the limit is 1000.
 *
 * [1] https://help.shopify.com/en/manual/products/variants/add-variants
 */
export const GET_PRODUCTS = {
  name: 'fetchProducts',
  gql: gql`
    query getProducts($after: String, $query: String) {
      products(first: 9, after: $after, query: $query) {
        pageInfo {
          hasPreviousPage
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            title
            status
            priceRangeV2 {
              minVariantPrice {
                amount
                currencyCode
              }
              maxVariantPrice {
                amount
                currencyCode
              }
            }
            featuredImage {
              transformedSrc
            }
            variants(first: 100) {
              edges {
                node {
                  id
                  title
                  price
                }
              }
            }
          }
        }
      }
    }
  `,
};

export const GET_PRODUCTS_FOR_BAB = {
  name: 'fetchProducts',
  gql: gql`
    query getProducts(
      $after: String
      $query: String
      $countryCode: CountryCode
    ) {
      products(first: 2, after: $after, query: $query) {
        pageInfo {
          hasPreviousPage
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            title
            status
            options {
              name
              values
            }
            priceRangeV2 {
              minVariantPrice {
                amount
                currencyCode
              }
              maxVariantPrice {
                amount
                currencyCode
              }
            }
            featuredImage {
              transformedSrc
            }
            tracksInventory
            totalInventory
            onlineStorePreviewUrl
            hasOnlyDefaultVariant
            productType
            variants(first: 100) {
              edges {
                node {
                  id
                  title
                  price
                  image {
                    url
                  }
                  contextualPricing(context: { country: $countryCode }) {
                    price {
                      amount
                      currencyCode
                    }
                  }
                  inventoryPolicy
                  inventoryManagement
                  inventoryQuantity
                  sellableOnlineQuantity
                  selectedOptions {
                    name
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
};

export const GET_PRODUCT_WITH_VARIANTS_BY_ID = {
  name: 'getProductWithVariantsById',
  gql: gql`
    query getProductWithVariantsByID($id: ID!) {
      product(id: $id) {
        id
        title
        hasOnlyDefaultVariant
        featuredImage {
          transformedSrc
        }
        variants(first: 50) {
          edges {
            node {
              id
              title
            }
          }
        }
      }
    }
  `,
};

export const GET_PRODUCT = {
  name: 'fetchProduct',
  gql: gql`
    query getProduct($id: ID!, $countryCode: CountryCode) {
      product(id: $id) {
        id
        title
        status
        options {
          name
          values
        }
        description
        descriptionHtml
        priceRangeV2 {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        featuredImage {
          transformedSrc
        }
        tracksInventory
        totalInventory
        onlineStorePreviewUrl
        hasOnlyDefaultVariant
        productType
        variants(first: 100) {
          edges {
            node {
              id
              title
              price
              image {
                url
              }
              contextualPricing(context: { country: $countryCode }) {
                price {
                  amount
                  currencyCode
                }
              }
              inventoryPolicy
              inventoryManagement
              inventoryQuantity
              sellableOnlineQuantity
              selectedOptions {
                name
                value
              }
            }
          }
        }
      }
    }
  `,
};
export const GET_PRODUCT_WITH_SELLING_PLAN_GROUPS = {
  name: 'getProductWithSellingPlanGroups',
  gql: gql`
    query getProduct($id: ID!) {
      product(id: $id) {
        id
        title
        status
        options {
          name
          values
        }
        priceRangeV2 {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        featuredImage {
          transformedSrc
        }
        tracksInventory
        totalInventory
        onlineStorePreviewUrl
        hasOnlyDefaultVariant
        productType
        variants(first: 10) {
          edges {
            node {
              id
              title
              sellingPlanGroups(first: 5) {
                edges {
                  node {
                    id
                    name
                    sellingPlans(first: 5) {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
};

export const GET_PRODUCT_WITH_VARIANTS_ONLY = {
  name: 'fetchProduct',
  gql: gql`
    query getProduct($id: ID!) {
      product(id: $id) {
        id
        variants(first: 100) {
          edges {
            node {
              id
              title
              price
              inventoryQuantity
              sellableOnlineQuantity
              selectedOptions {
                name
                value
              }
            }
          }
        }
      }
    }
  `,
};

export const GET_COLLECTION = {
  name: 'getCollection',
  gql: gql`
    query getCollection($id: ID!) {
      collection(id: $id) {
        id
        title
        productsCount
        handle
        image {
          originalSrc
        }
      }
    }
  `,
};

export const GET_PRODUCTS_COLLECTION = {
  name: 'getProductsCollection',
  gql: gql`
    query getCollection(
      $id: ID!
      $first: Int
      $last: Int
      $afterCursor: String
      $beforeCursor: String
    ) {
      collection(id: $id) {
        id
        productsCount
        products(
          first: $first
          last: $last
          before: $beforeCursor
          after: $afterCursor
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
            }
          }
        }
      }
    }
  `,
};

export const GET_PRODUCTS_WITH_VARIANTS_COLLECTION = {
  name: 'getProductsCollection',
  gql: gql`
    query getCollection(
      $id: ID!
      $first: Int
      $last: Int
      $afterCursor: String
      $beforeCursor: String
    ) {
      collection(id: $id) {
        id
        productsCount
        products(
          first: $first
          last: $last
          before: $beforeCursor
          after: $afterCursor
        ) {
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              id
              title
              productType
              featuredImage {
                url
              }
              variants(first: 100) {
                edges {
                  node {
                    title
                    id
                    image {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
};

export const GET_VARIANT = {
  name: 'getVariant',
  gql: gql`
    query getProductVariant($id: ID!) {
      productVariant(id: $id) {
        id
        title
        displayName
        price
        image {
          url
        }
        inventoryPolicy
        inventoryManagement
        inventoryQuantity
        sellableOnlineQuantity
        selectedOptions {
          name
          value
        }
        product {
          id
          title
          hasOnlyDefaultVariant
          status
          productType
          featuredImage {
            transformedSrc
          }
        }
      }
    }
  `,
};

export const GET_VARIANT_TITLE_AND_IMAGE = {
  name: 'getVariantTitleAndImage',
  gql: gql`
    query getVariantTitleAndImage($id: ID!) {
      productVariant(id: $id) {
        id
        displayName
        image {
          url
        }
        product {
          id
          featuredImage {
            transformedSrc
          }
        }
      }
    }
  `,
};

export const GET_VARIANT_WITH_SELLING_PLANS = {
  name: 'getVariantWithSellingPlans',
  gql: gql`
    query getVariantsById($ids: [ID!]!) {
      nodes(ids: $ids) {
        ... on ProductVariant {
          id
          title
          product {
            requiresSellingPlan
          }
          sellingPlanGroups(first: 5) {
            nodes {
              id
              name
              sellingPlans(first: 5) {
                nodes {
                  id
                  name
                  pricingPolicies {
                    __typename
                    ... on SellingPlanRecurringPricingPolicy {
                      adjustmentType
                      adjustmentValue {
                        __typename
                        ... on SellingPlanPricingPolicyPercentageValue {
                          percentage
                        }
                        ... on MoneyV2 {
                          amount
                          currencyCode
                        }
                      }
                      afterCycle
                      createdAt
                    }
                    ... on SellingPlanPricingPolicyBase {
                      adjustmentType
                      adjustmentValue {
                        __typename
                        ... on SellingPlanPricingPolicyPercentageValue {
                          percentage
                        }
                        ... on MoneyV2 {
                          amount
                          currencyCode
                        }
                      }
                    }
                    ... on SellingPlanFixedPricingPolicy {
                      adjustmentType
                      adjustmentValue {
                        __typename
                        ... on SellingPlanPricingPolicyPercentageValue {
                          percentage
                        }
                        ... on MoneyV2 {
                          amount
                          currencyCode
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
};

export const GET_VARIANTS_BY_ID_WITH_CONTEXTUAL_PRICING = {
  name: 'getVariantsByIdWithContextualPricing',
  gql: gql`
    query getVariantsById($ids: [ID!]!, $countryCode: CountryCode) {
      nodes(ids: $ids) {
        ... on ProductVariant {
          id
          contextualPricing(context: { country: $countryCode }) {
            price {
              amount
              currencyCode
            }
          }
        }
      }
    }
  `,
};

export const GET_VARIANTS_WITH_PRODUCT = {
  name: 'getVariantsWithProduct',
  gql: gql`
    query getVariantsById($ids: [ID!]!) {
      nodes(ids: $ids) {
        ... on ProductVariant {
          id
          title
          image {
            url
          }
          product {
            id
            title
            featuredImage {
              transformedSrc
            }
          }
        }
      }
    }
  `,
};

export const GET_PRODUCTS_WITH_VARIANT_IDS = {
  name: 'getProductsWithVariantsIds',
  gql: gql`
    query getProductsWithVariantsIds($ids: [ID!]!) {
      nodes(ids: $ids) {
        ... on Product {
          id
          title
          featuredImage {
            transformedSrc
          }
          variants(first: 100) {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  `,
};

export const GET_ORDER_FULFILLMENTS = {
  name: 'getOrderFulfillments',
  gql: gql`
    query getOrderFulfillments($id: ID!) {
      order(id: $id) {
        id
        name
        displayFulfillmentStatus
        fulfillmentOrders(first: 24) {
          edges {
            node {
              id
              status
              fulfillAt
            }
          }
        }
      }
    }
  `,
};

export const ORDER_FULFILLMENT_RESCHEDULE = {
  name: 'orderFulfillmentReschedule',
  gql: gql`
    mutation fulfillmentOrderReschedule($fulfillAt: DateTime!, $id: ID!) {
      fulfillmentOrderReschedule(fulfillAt: $fulfillAt, id: $id) {
        fulfillmentOrder {
          id
        }
        userErrors {
          field
          message
        }
      }
    }
  `,
};

export const CREATE_BASIC_DISCOUNT = {
  name: 'discountCodeBasicCreate',
  gql: gql`
    mutation discountCodeBasicCreate(
      $basicCodeDiscount: DiscountCodeBasicInput!
    ) {
      discountCodeBasicCreate(basicCodeDiscount: $basicCodeDiscount) {
        userErrors {
          field
          message
          code
        }
        codeDiscountNode {
          id
        }
      }
    }
  `,
};

export const DELETE_DISCOUNT = {
  name: 'discountCodeDelete',
  gql: gql`
    mutation discountCodeDelete($id: ID!) {
      discountCodeDelete(id: $id) {
        userErrors {
          field
          code
          message
        }
      }
    }
  `,
};
